import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export const AuthContext = createContext();
const login = (userData) => {
  sessionStorage.setItem("user", JSON.stringify(userData));
  sessionStorage.setItem("adminToken", userData.token);
};

const handleLogOut = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("adminToken");
  window.location.href = "/";
  toast.success("You have been successfully logged out.");
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("adminToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});

  let data = {
    login,
    userLoggedIn: isLogin,
    setIsLogin,
    userData,
    userLogIn: (type, data) => {
      setIsLogin(type);
    },
    handleLogOut,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
