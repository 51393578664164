import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Footer from "./footer";

const Root = styled('div')(({ theme, themeMode }) => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  background: themeMode === "DARK"
    ? "url('images/darkbackground.png')"
    : "url('images/lightbackground.png')",
}));

const Wrapper = styled('div')(({ theme, isDesktop }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  position: "relative",
  paddingTop: 70,
  minHeight: "calc(100vh - 70px)",
  background: 'rgba(235,237,239)',
  paddingLeft: isDesktop ? 256 : 0,
  paddingBottom: theme.breakpoints.down("xs") ? "50px" : 0,
  "@media (max-width:767px)": {
    paddingTop: "70px !important",
  },
}));

const ContentContainer = styled('div')({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const Content = styled('div')(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  // padding: theme.breakpoints.only("md") ? "30px 28px 30px" : 
  //          theme.breakpoints.only("sm") ? "5px 5px 5px" : 
  //          theme.breakpoints.only("xs") ? "0px" : "12px 25px 25px",
 
}));

const DashboardLayout = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Arbitrage");
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (location) {
      if (location.pathname === "/sniper-dashboard" || 
          location.pathname === "/bot-setting" || 
          location.pathname === "/sniper-transactions") {
        setSelectedTab("Sniper");
      }
    }
  }, [location]);

  const themeMode = "LIGHT"; // Replace this with logic to determine theme mode

  return (
    <Root themeMode={themeMode}>
      <TopBar 
        onMobileNavOpen={() => setMobileNavOpen(true)}   
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
      />
      
      <NavBar
        tabView={selectedTab}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
      <Wrapper themeMode={themeMode} isDesktop={isDesktop}>
        <ContentContainer>
          <Content sx={{ 
            padding: {
    md: "23px 28px 30px",
    sm: "5px 5px 5px",
    xs: "0px",
  }}} id="main-scroll">
            {children}
          </Content>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </Root>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;




