import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createMuiTheme } from "./theme";
import AuthProvider from "./context/Auth";
import PageLoading from "./components/PageLoading";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { routes } from "./routes";
import "./scss/main.css";

function App() {
  const theme = createMuiTheme({ theme: "light", direction: "ltr" });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider>
            <Router>
              <RenderRoutes data={routes} />
            </Router>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

function RenderRoutes({ data }) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        {data.map((route, i) => {
          const Component = route.component;
          const Guard = route.auth ? AuthGuard : React.Fragment;
          const Layout = route.layout || React.Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Toaster position="top-right" />
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component />
                    )}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default App;
