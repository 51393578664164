import { lazy } from "react";
// import DashboardLayout from "./layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout";
import DashBoardLayOut from "../src/layouts/DashBoardLayOut/index.js";

export const routes = [
  {
    exact: true,
    auth: false,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/Login.jsx")),
  },
  {
    exact: true,
    auth: false,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ForgotPassword.js")),
  },
  {
    exact: true,
    auth: false,
    path: "/otpVerification",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/OTPVerification.js")),
  },
  {
    exact: true,
    auth: false,
    path: "/resetPassword",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ResetPassword.js")),
  },
  {
    exact: true,
    auth: true,
    path: "/dashboard",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/DashboardMain/LandingPage.js")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/SubAdminListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin/addSubAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/AddSubAdmin.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin/editSubAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/EditSubAdmin.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/profile",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/MyAccountManagement/AccountProfilePage.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/profile/changePassword",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/MyAccountManagement/ChangePassword.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/leadsListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/LeadsManagement/LeadsListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/leadsListing/leaddetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/LeadsManagement/LeadDetails.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/hrleadslisting",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/HrLeadsManagement/LeadsListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/schedulelisting",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/ScheduleManagement/ScheduleListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/CategoryListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category/addcategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/AddCategory.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category/editcategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/EditCategory.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category/viewcategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/QuestionListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category/addquestion",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/AddQuestion.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/category/editquestion",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CategoryandQuestionsManagement/EditQuestion.jsx")
    ),
  },


];
