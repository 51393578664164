import React from 'react';
import { Container, Box, Typography, AppBar, Toolbar, Button, CssBaseline, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logoWhite.png';

const Header = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: 'sticky',
}));

const Footer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const AuthLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header>
        <Toolbar>
          <Box variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            <img
              src={logo}
              alt="healthcare_logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" , height:"55px" }}
            />
          </Box>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </Header>
      <Content>
        <Container maxWidth="sm">
          {children}
        </Container>
      </Content>
      <Footer>
        <Typography variant="body2" color="textPrimary">
          © {new Date().getFullYear()} Admin panel Developed By Mobiloitte Technologies
        </Typography>
      </Footer>
    </Box>
  );
};

export default AuthLayout;
