import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LoadingImg from "../assets/MobiLogo.jpg"

const Root = styled('div')(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center",
  zIndex: 2000,
}));

const Loader = styled('img')(({ theme }) => ({
  width: 300,
  maxWidth: "100%",
  // [theme.breakpoints.down("xs")]: {
  //   width: 140,
  // },
}));

const ProgressBar = styled('div')({
  height: "3px",
});

export default function PageLoading() {
  return (
    <Root>
      <Box sx={{backgroundColor: '#2c78f5', padding: '10px'}}>
        {/* <LinearProgress height={10} /> */}
        <Loader sx={{
          width:{
            xs: 140,
            sm: 180,
            md: 220,
            lg: 280,
            xl: 360,
          }
        }} src={LoadingImg} alt="loader" />
      </Box>
    </Root>
  );
}
